import $ from 'jquery';
import 'slick-carousel';

window.jQuery = window.$ = $;

// Import our CSS
import "@/scss/app.scss";

// require("@fancyapps/fancybox");
import '@fancyapps/fancybox';

import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

import './parts/magiczoom.js';
import './parts/jquery.validate.min.js';

// alpine
import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';
Alpine.plugin(ui);
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

$(function() {
    /** home banner slider */
    var $sliderHero = $('#slick-hero');
    if ($sliderHero.length) {
        var total = $('#slick-hero .slide').length,
            rand = Math.floor(Math.random() * total);
        $sliderHero.slick({
            autoplay: true,
            autoplaySpeed: 5000,
            initialSlide: rand,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow absolute bg-none border-0 px-5 py-1.5 text-4xl translate-y-1/2 z-10 bg-yellow-500 text-white rounded-full ml-0 md:ml-3" aria-label="Prev" role="button" style="left: 5px; top: calc(50% - 1.6rem);outline: none;"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow absolute bg-none border-0 px-5 py-1.5 text-4xl translate-y-1/2 z-10 bg-yellow-500 text-white rounded-full mr-0 md:mr-3" aria-label="Next" role="button" style="right: 5px; top: calc(50% - 1.6rem);outline: none;"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            dots: true,
            dotsClass: ' flex justify-center absolute left-1/2 transform -translate-y-12 md:-translate-y-32 -translate-x-1/2 active-yellow-500 ',
            customPaging: function (slider, i) {
                return '<i class="fa fa-circle mx-1 text-white cursor-pointer" aria-hidden="true"></i>';
            },
            rows: 0, // Fix for extra div in v1.8.0-1
        });
    }

    /** home testimonials slider */
    var $sliderTestimonial = $('.slider-testimonial .slides');
    if ($sliderTestimonial.length) {
        $sliderTestimonial.slick({
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            dotsClass: ' flex justify-center active-blue-500',
            customPaging: function (slider, i) {
                return '<i class="fa fa-circle mx-1 text-white cursor-pointer" aria-hidden="true"></i>';
            },
            rows: 0, // Fix for extra div in v1.8.0-1
        });
    }

    var $gallery = $('.gallery-big-image .slick-slider');
    var $galleryThumb = $('.gallery-thumb .slick-slider');
    if ($gallery.length) {
        $gallery.slick({
            autoplay: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            cssEase: 'linear',
            rows: 0, // Fix for extra div in v1.8.0-1
            asNavFor: $galleryThumb,
        });

        $gallery.on('beforeChange', function() {
            console.log('before change gallery');
        });

        $galleryThumb.slick({
            autoplay: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            focusOnSelect: true,
            asNavFor: $gallery,
            rows: 0, // Fix for extra div in v1.8.0-1
            prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow absolute bg-none border-0 px-5 text-4xl translate-y-1/2 z-10" aria-label="Prev" role="button" style="left: 5px; top: calc(50% - 1.6rem);"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow absolute bg-none border-0 px-5 text-4xl translate-y-1/2 z-10" aria-label="Next" role="button" style="right: 5px; top: calc(50% - 1.6rem);"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    $(document).on('click', '.btn-menu', function (event) {
        var slideWidth = $('.header-inner').outerWidth();
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).find('svg').addClass('text-red-600').removeClass('text-blue-500');
            $('body').css('transform', 'translateX(-' + slideWidth + 'px)');
            $(this).find('path.top').css('transform', 'rotate(45deg) translateY(8px)');
            $(this).find('path.mid').css({
                'width': '0',
                'opacity': '0',
                'visibility': 'hidden'
            });
            $(this).find('path.bot').css('transform', 'rotate(-45deg) translateY(-8px)');
        } else {
            // $('body').css('transform', 'translateX(0)');
            $('body').removeAttr('style');
            $(this).find('svg').addClass('text-blue-500').removeClass('text-yellow-500');
            $(this).find('path.top').css('transform', 'rotate(0) translateY(0)');
            $(this).find('path.mid').css({
                'width': '100%',
                'opacity': '1',
                'visibility': 'visible'
            });
            $(this).find('path.bot').css('transform', 'rotate(0) translateY(0)');
        }
        //$('.wrapper').toggleClass('move-left');
        //$('html').toggleClass('fixed');

        event.preventDefault();
    });

    $(document).on('click', '.header-inner .parent', function (event) {
        //event.preventDefault();
        //event.stopPropagation();
        // var el = $(this);
        // var link = el.attr("href");
        // window.location = link;
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.parent .dropdown').slideUp(300);
        } else {
            $('.header-inner .parent').removeClass('active');
            $(this).addClass('active');
            $('.parent .dropdown').slideUp(300);
            $(this).find('.dropdown').slideDown(300);
        }
    });
    $(document).on('click', '.accordion-toggle', function (event) {
        $(this).next('ul').slideToggle();
    });

    //product swatches
    $('.swatch-multi').on('click', function(e) {
        e.preventDefault();
		$(this).closest('.list-color-palette').find('.selected.border-2.border-yellow-500 ').removeClass('selected border-2 border-yellow-500 ').addClass('border border-gray-400 ');
		$(this).removeClass('border border-gray-400');
		$(this).addClass('selected border-2 border-yellow-500 ');
		// https://cf.pcaproducts.com/images/doors/A-100_angle_wh.jpg?mtime=20161129143343
		var s3 = 'https://cf.pcaproducts.com/images/doors/';
		var id = $(this).data('id');
		var model = $(this).data('model');
		var color = $(this).data('color');
		$('#door-'+ id).attr('src', s3 + model + '_angle_' + color + '.jpg');
	});

	var $gallery = $('.gallery-big-image .slick-slider');
	var $galleryThumb = $('.gallery-thumb .slick-slider');

    $('.swatch-single').on('click', function(e) {
        e.preventDefault();

        var $el = $(this);
        var slideIndex = $el.data('slide');
        var modelNum = $el.data('model');
        var colorVal = $el.data('color');

        $('#dvUrl').attr('href', '/visualizer?modelNum=' + modelNum + '&colorValue=' + colorVal);

        $('.list-color-palette label').removeClass('selected border-3 border-yellow-500').addClass('border');
        $el.removeClass('border').addClass('selected border-3 border-yellow-500');
        // console.log(slideIndex);

        // Return to this w/mobile bug
        // MagicZoom.zoomOut();
        $gallery.slick('slickGoTo', slideIndex, true);

    });

    $(document).on('change', '.select-filter', function (event) {
        var filterSelection = $(this).val();
        var filterType = $(this).data('type');

        $('body,html').animate({
            scrollTop : $('#doors').offset().top - 100
        }, 500);

        $('.card-door').hide();

        if (filterSelection === '') {
            $('.card-door').fadeIn();
        } else {
            $('.card-door.' + filterSelection).fadeIn(450);
        }

        console.log(filterSelection + ' ' + filterType);
    });

    $(document).on('keyup keypress', '#form-filters', function (event) {
        var keyCode = event.keyCode || event.which;
        if (keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    $(document).on('click', '#filter-reset', function (event) {
        event.preventDefault();
        $('.select-filter').val('');
        $('.card-door').fadeIn();

        $('body,html').animate({
            scrollTop : $('#doors').offset().top - 100
        }, 500);
    });

    $(window).scroll(function() {
        var scrollPos = $(this).scrollTop();

        // If page is scrolled more than 800px
        if (scrollPos >= 800) {
            $('#return-to-top').fadeIn(200);
        } else {
            $('#return-to-top').fadeOut(200);
        }
    });

    $('#return-to-top').click(function() {
        $('body,html').animate({
            scrollTop : 0
        }, 500);
    });

    /** product details feature option tabbing */
    var $sliderBoxLg = $('.slider-box-lg');
    var $sliderNavLg = $('.slider-nav-lg');
    if ($sliderBoxLg.length) {
        $sliderBoxLg.on('init', function(event, slick){
            var $items = slick.$dots.find('li');
            $items.addClass('w-3 h-3 bg-gray-300 border rounded-full mx-1 lg:mx-0 lg:my-1 cursor-pointer ');
            $items.find('button').remove();
        });
        $sliderBoxLg.slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            dotsClass: ' feature-list-dots flex justify-center pt-4 lg:pt-0 lg:block lg:absolute lg:top-1/2 lg:right-0 lg:transform lg:-translate-y-1/2 lg:-translate-x-full ',
            asNavFor: $sliderNavLg,
            fade: true,
            cssEase: 'linear',
            rows: 0, // Fix for extra div in v1.8.0-1
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        vertical: false,
                        adaptiveHeight: true
                    }
                }

            ]
        });

        $sliderNavLg.slick({
            infinite: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            arrows: false,
            focusOnSelect: true,
            vertical: true,
            asNavFor: $sliderBoxLg,
            rows: 0, // Fix for extra div in v1.8.0-1
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 1,
                        vertical: false
                    }
                }/*, {
                    breakpoint: 600,
                    settings: {
                        infinite: false,
                        slidesToShow: 5,
                        slidesToScroll: 4,
                        vertical: false
                    }
                }*/
            ]
        });
    }

    $(document).on('submit', 'form#search-dealer-form', function(event) {
        event.preventDefault();
        _this = $(this);
        _this.find('.loader').show();
        $.post(_this.attr('action'), _this.serialize(), function(data, textStatus, xhr) {
            _this.find('.loader').hide();
            $('.results-wrapper').html(data);
            $('.search-wrapper').hide();
            $('.results-wrapper').show();

            if ($('.results-wrapper').find('#dealer-results').length) {
                addTableSort('dealer-results');
            }
        });
    });

});

$(window).on('load', function () {
    var $sliderProducts = $('.slider-products .slides');
    if ($sliderProducts.length) {

        var $progressBar = $('.progress');
            var $progressBarLabel = $( '.slider__label' );
            var $progressBarInner = $( '.progress-bar' );

            $sliderProducts.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var modifier = ( $(window).width() < 600 ) ? 0 : 2;
                var modCount = (nextSlide === 0) ? nextSlide : nextSlide + modifier;
                var calc = ( (modCount) / (slick.slideCount-1) ) * 100;
                if(calc > 100) calc = 100;

                // console.log(modCount + '|' + slick.slideCount);

                //$progressBar.css('background-size', calc + '% 100%').attr('aria-valuenow', calc );
                $progressBarInner.css('width', calc +'%');
                $progressBarLabel.text( calc + '% completed' );
            });


        $sliderProducts.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            rows: 0, // Fix for extra div in v1.8.0-1
            prevArrow: '<button type="button" data-role="none" class="w-10 h-10 xl:w-12 xl:h-12 bg-white flex items-center justify-center absolute left-0 -mb-2 ml-2 xl:ml-0 xl:mb-0 bottom-0 xl:bottom-auto xl:top-1/2 transform translate-y-full xl:-translate-y-1/2 xl:-translate-x-full rounded-full text-2xl xl:text-4xl focus:outline-none slick-next slick-arrow" aria-label="Next" role="button"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
            nextArrow: '<button type="button" data-role="none" class="w-10 h-10 xl:w-12 xl:h-12 bg-white flex items-center justify-center absolute right-0 -mb-2 mr-2 xl:mr-0 xl:mb-0 bottom-0 xl:bottom-auto xl:top-1/2 transform translate-y-full xl:-translate-y-1/2 xl:translate-x-full rounded-full text-2xl xl:text-4xl focus:outline-none slick-prev slick-arrow" aria-label="Prev" role="button"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    }
});
